<template>
  <div class="graph-container" ref="container">
    <div id="isEmpty" ref="isEmpty">
      <h4>No Nodes or Edges selected.</h4>
      <h5>Filter in using Thoughtversecontrols to view</h5>
    </div>
    <div id="3d-force" ref="forcegraph"></div>
  </div>
</template>

<script>
// import ForceGraph3D from '3d-force-graph';

// import { mapActions } from 'vuex';

export default {
  // components: {},
  // name: 'Thoughtversealt',

  // data() {
  //   return {
  //     showgraphSetting: true,
  //   };
  // },

  // mounted() {
  //   this.$refs.isEmpty.hidden = false;
  //   const Graph = ForceGraph3D()(this.$refs.forcegraph)
  //     .height(this.$refs.container.clientHeight * 0.7)
  //     .width(this.$refs.container.clientWidth * 0.98)
  //     .nodeAutoColorBy('nodeLabel')
  //     .nodeLabel((x) => (x.title ? x.title : x.name))
  //     .linkAutoColorBy('label')
  //     .linkCurvature(0.4)
  //     .onNodeClick((x) => this.setSelectedNode(x));

  //   this.graph = Graph;
  // },

  // methods: {
  //   ...mapActions({
  //     graphDumpFetch: 'graphDumpFetch',
  //   }),
  //   updateSettings() {
  //     const graphSetting = this.$store.state.graph.setting;
  //     if (graphSetting) {
  //       this.showgraphSetting = graphSetting.showSettings;
  //     }
  //   },
  //   setSelectedNode(selectedNode) {
  //     this.$store.dispatch('setSelectedNode', selectedNode);
  //   },
  //   updateGraphBasedOnSettings() {
  //     const graphFromState = this.$store.state.graph;
  //     const graphSettings = this.$store.state.graph.setting;

  //     const filteredNodeList = graphFromState.nodes.filter(() => true);

  //     const filteredEdgeList = graphFromState.links.filter((edge) => {
  //       const labelToCheck = edge.label;

  //       if (
  //         graphSettings.edgeFilter[labelToCheck]
  //         && graphSettings.edgeFilter[labelToCheck].isSelected > 0
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });

  //     if (filteredNodeList.length > 0) {
  //       this.$refs.isEmpty.hidden = true;
  //     } else {
  //       this.$refs.isEmpty.hidden = false;
  //     }
  //     this.graph
  //       .graphData({
  //         nodes: filteredNodeList,
  //         links: filteredEdgeList,
  //       })
  //       .nodeVal((x) => graphSettings.nodeFilter[x.nodeLabel].nodeSize || 5)
  //       .linkWidth((x) => graphSettings.edgeFilter[x.label].edgeWidth || 0);
  //     // .nodeColor((x) => graphSettings.nodeFilter[x.nodeLabel].color);
  //   },
  // },

  // created() {
  //   // this.graphDumpFetch();
  //   this.showgraphSetting = true;
  //   this.unsubscribe = this.$store.subscribe((mutation) => {
  //     if (
  //       mutation.type === 'setGraph'
  //       || mutation.type === 'SET_FG_COMPONENT_SETTING'
  //     ) {
  //       this.updateGraphBasedOnSettings();
  //     }

  //     if (
  //       mutation.type === 'SET_FG_COMPONENT_SETTING'
  //       || mutation.type === 'SET_SETTING'
  //     ) {
  //       this.updateSettings();
  //     }
  //   });
  // },
  // computed: {},

  // beforeDestroy() {
  //   this.unsubscribe();
  // },
};
</script>

<style lang="scss" scoped>
.container {
  height: 80vh;
}
.graph-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#theScene {
  width: 100%;
  height: 100%;
}
#isEmpty {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  justify-content: center;
  color: antiquewhite;
}
</style>
